<template>
  <FormInputWidget
    :id="inputId"
    :notRequired="notRequired"
    :label="label" :hideLabel="hideLabel"
    :hasError="hasError" :errorMsg="errorMsg"
  >
    <template v-if="$slots.label" #label="{ label, notRequired }">
      <slot name="label" :label="label" :notRequired="notRequired" />
    </template>

    <FormInputTextarea :id="inputId" :notRequired="notRequired" :placeholder="placeholder" :rows="rows" :disabled="disabled" :not-resizable="notResizable" :with-counter="withCounter" :value="value" @input="emitInput" />
  </FormInputWidget>
</template>

<script>

import formInputWidgetMixin from '../mixins/formInputWidget.js';
import formTextareaPropsMixin from '../mixins/formTextareaProps.js';

import FormInputWidget from '../Widgets/FormInputWidget.vue';
import FormInputTextarea from '../FormInputTextarea.vue';

export default {
  mixins: [formInputWidgetMixin, formTextareaPropsMixin],
  components: {
    FormInputWidget,
    FormInputTextarea,
  },
};

</script>
