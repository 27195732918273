import formInputFieldMixin from './formInputField.js';
import formTextareaPropsMixin from './formTextareaProps.js';

export default {
  mixins: [formInputFieldMixin, formTextareaPropsMixin],
  data () {
    return {
      internalValue: this.max ? this.cutOnMaxLength(this.value) : null,
    };
  },
  computed: {
    remainingChars () {
      if (!this.withCounter || !this.max) {
        return null;
      }

      return this.max - this.internalValue.length;
    },
  },
  watch: {
    value (newValue) {
      if (!this.max) {
        return;
      }

      this.internalValue = this.cutOnMaxLength(newValue);
    },
  },
  methods: {
    cutOnMaxLength (value) {
      return (value || '').toString().substr(0, this.max);
    },
    onInput (event) {
      if (!this.withCounter) {
        this.$emit('input', event.target.value);

        return;
      }

      this.internalValue = this.internalValue.substr(0, this.max);
      this.$emit('input', this.internalValue);
    },
  },
};
