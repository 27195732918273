<template>
  <div v-if="withCounter" class="form-textarea-counter">
    <textarea
      class="form-control form-textarea-counter__textarea" :class="{ 'not-resizable': notResizable }"
      :id="inputId"
      :rows="rows"
      :required="!notRequired"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="disabled"
      v-model="internalValue"
      @input="onInput"
    />
    <div class="form-textarea-counter__counter" :class="{ 'no-chars-left': 0 === remainingChars }">
      {{ remainingChars }} / {{ max }}
    </div>
  </div>
  <textarea v-else
    class="form-control" :class="{ 'not-resizable': notResizable }"
    :id="inputId"
    :rows="rows"
    :required="!notRequired"
    :placeholder="placeholder"
    :disabled="disabled"
    :readonly="disabled"
    :value="value"
    @input="onInput"
  />
</template>

<script>

import formTextareaMixin from './mixins/formTextarea.js';

export default {
  mixins: [formTextareaMixin],
};

</script>

<style lang="scss">

$form-input-textarea-no-chars-color: red !default;

.form-textarea-counter {
  &__textarea.not-resizable {
    resize: none;
  }

  &__counter {
    text-align: right;
    padding: 3px;

    &.no-chars-left {
      color: $form-input-textarea-no-chars-color;
    }
  }
}

</style>
