import formInputFieldPropsMixin from './formInputFieldProps.js';

export default {
  mixins: [formInputFieldPropsMixin],
  props: {
    rows: {
      type: Number,
      default: 6,
    },
    notResizable: {
      type: Boolean,
      default: false,
    },
    withCounter: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: 1000,
    },
  },
};
